<dashboard-widget>
  <div icon>
    <svg-icon>shop</svg-icon>
  </div>
  <div header1 matTooltip="Shops">
    Shops
  </div>
  <div content>
    <div class="menuboxouter tut_shopmenu_views">
      <div class="menubox menubox_small">
        <ng-container *ngFor="let v of views">
          <a class="button mat-raised-button" routerLink="/shop/{{v.slug}}" *ngIf="v.allowed"
              [style.color]="v.getFontColor()" [class.hovertransparent]="v.getBGColor() != ''"
             routerLinkActive="active-link">
            <div [style.background-color]="v.getBGColor()" >
              <div style="padding:1rem;">
                <svg-icon size="40">{{v.getIcon()}}</svg-icon>
                <div>{{v.getLabel()}}</div>
              </div>

            </div>
          </a>

          <a class="button disabled mat-raised-button" *ngIf="!v.allowed"
             [style.background-color]="v.getBGColor()" [style.color]="v.getFontColor()" [class.hovertransparent]="v.getBGColor() != ''"
             routerLinkActive="active-link">
            <div>
              <svg-icon size="40">{{v.getIcon()}}</svg-icon>
              {{v.getLabel()}}
              <br>
              Keine Berechtigung
            </div>
          </a>
        </ng-container>

        <div *ngIf="views && views.length == 0" class="infobox" style="text-align: center">
          <h1>
            <info-box type="shop_menu_no_views"></info-box>
          </h1>
        </div>
      </div>
    </div>
  </div>
</dashboard-widget>
