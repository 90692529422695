import {NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterModule,
  Routes
} from '@angular/router';

import {AppGuardsAuthguard} from './guards/app.guards.authguard';
import {AppUserLogoutComponent} from './components/logout/app.user.logout.component';
import {AppLoginComponent} from './components/login/app.login.component';
import {AppNotificationListComponent} from './components/notification/app.notification.list.component';
import {AppSettingsComponent} from './components/settings/app.settings.component';
import {AppConfigService} from './services/app.config.service';
import {AppPageloaderService} from './services/app.pageloader.service';
import {AppLayoutsLoggedinComponent} from './components/layouts/app.layouts.loggedin.component';
import {AppLayoutsLoginComponent} from './components/layouts/app.layouts.login.component';
import {AppGuardsHasLieferkunde} from './guards/app.guards.haslieferkunde';
import {AppLieferkundeSelectorComponent} from './components/lieferkunde-selector/app.lieferkunde-selector.component';
import {AppRegisterComponent} from './components/register/app.register.component';
import {AppCustomerSettingsComponent} from './components/customer-settings/app.customer-settings.component';
import {AppResetPasswordComponent} from './components/reset-password/app.reset-password.component';
import {AppChangePasswordComponent} from './components/change-password/app.change-password.component';
import {AppGuardsCondition} from './guards/app.guards.condition';
import {
  AppCustomerConditionCheckerComponent
} from './components/customer-condition-checker/app.customer-condition-checker.component';
import {AppGuardsFeatureguard} from './guards/app.guards.featureguard';
import {AppValidateIpComponent} from './components/validate-ip/app.validate-ip.component';

const routes: Routes = [
  {
    path: 'mobile-file-uploader',
    loadChildren: () => import('./modules/file-upload-token/file-upload-token.module').then(m => m.FileUploadTokenModule),
  },
  {
    path: '',
    component: AppLayoutsLoggedinComponent,
    canActivate: [AppGuardsAuthguard],
    canActivateChild: [AppGuardsAuthguard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'check-conditions',
        component: AppCustomerConditionCheckerComponent,
        canActivate: [AppGuardsAuthguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition],
      },
      {
        path: 'menu',
        loadChildren: () => import('./modules/main-menu/main-menu.module').then(m => m.MainMenuModule),
        canActivate: [AppGuardsAuthguard, AppGuardsCondition],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition],
      },
      {
        path: 'analytics/documents',
        loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: [
            'Dokumente Lieferschein',
            'Dokumente DTP Lieferschein',
            'Dokumente Rechnung',
          ],
          features: ['Dokumentensuche']
        }
      },
      {
        path: 'analytics/statistics',
        loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: [
            'Statistiken - Umsatz Gemischt',
            'Statistiken - Umsatz Nur Hochpreis',
            'Statistiken - Umsatz Ohne Hochpreis',
            'Statistiken - Umsatz Import',
            'Statistiken - Umsatz Magistral',
            'Statistiken - Umsatz Emballagen',
            'Statistiken - Umsatz Problemartikel'
          ],
          features: ['Statistiken']
        }
      },
      {
        path: 'analytics/batch-documentation',
        loadChildren: () => import('./modules/batch-documentation/batch-documentation.module').then(m => m.BatchDocumentationModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Dokument Chargendokumentation'],
          features: ['ChargenDokumentation']
        }
      },
      {
        path: 'analytics/residues',
        loadChildren: () => import('./modules/residues/residues.module').then(m => m.ResiduesModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Rueckstaende'],
          features: ['Rueckstaende']
        }
      },
      {
        path: 'analytics/abrufvereinbarungen',
        loadChildren: () => import('./modules/abrufvereinbarungen/abrufvereinbarungen.module').then(m => m.AbrufvereinbarungenModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Abrufvereinbarungen und Vorauszahlungen'],
          features: ['Abrufvereinbarungen']
        }
      },
      {
        path: 'analytics/order-archive',
        loadChildren: () => import('./modules/order-archive/order-archive.module').then(m => m.OrderArchiveModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Auftragsarchiv'],
          features: ['Auftragsarchiv']
        }
      },
      {
        path: 'analytics/delivery-pharmacy',
        loadChildren: () => import('./modules/delivery-pharmacy/delivery-pharmacy.module').then(m => m.DeliveryPharmacyModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Lieferapotheke'],
          features: ['Lieferapotheke']
        }
      },
      {
        path: 'enrollment/stock-levels',
        loadChildren: () => import('./modules/stock-levels/stock-levels.module').then(m => m.StockLevelsModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Bestandsmeldungen'],
          features: ['Bestandsmeldungen']
        }
      },
      {
        path: 'enrollment/enclosed-shipping',
        loadChildren: () => import('./modules/enclosed-shipping/enclosed-shipping.module').then(m => m.EnclosedShippingModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Beipackanmeldung', 'Beipackanmeldung Dokumente an Betriebsstätte'],
          features: ['Beipack']
        }
      },
      {
        path: 'enrollment/return',
        loadChildren: () => import('./modules/return-registration/return-registration.module').then(m => m.ReturnRegistrationModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Apotheken'],
          features: ['ReturnRegistration']
        }
      },
      {
        path: 'enrollment/pre-order',
        loadChildren: () => import('./modules/pre-order/pre-order.module').then(m => m.PreOrderModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          oneOfRoles: ['Vorbestellung'],
          features: ['Vorbestellung']
        }
      },
      {
        path: 'enrollment/recipe-upload',
        loadChildren: () => import('./modules/recipe-upload/recipe-upload.module').then(m => m.RecipeUploadModule),
        canActivate: [AppGuardsAuthguard, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        data: {
          // oneOfRoles: ['RezeptUpload'],
          features: ['RezeptUpload']
        }
      },
      {
        path: 'change-password',
        component: AppChangePasswordComponent,
        canActivate: [AppGuardsAuthguard, AppGuardsCondition],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition],
      },
      {
        path: 'news',
        loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule),
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition],
      },
      {
        path: 'downloads',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        loadChildren: () => import('./modules/downloads/downloads.module').then(m => m.DownloadsModule),
        data: {
          oneOfRoles: ['Download'],
          features: ['Downloads']
        }
      },
      {
        path: 'calendar',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule),
        data: {
          oneOfRoles: ['Kalender'],
          features: ['Calendar']
        }
      },
      {
        path: 'tour-browser',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/tour-browser/tour-browser.module').then(m => m.TourBrowserModule),
        data: {
          oneOfRoles: ['Tourenbrowser'],
          features: ['Touren']
        }
      },
      {
        path: 'account',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'call-plan',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/call-plan/call-plan.module').then(m => m.CallPlanModule),
        data: {
          oneOfRoles: ['Anrufplan'],
          features: ['CallPlan']
        }
      },
      {
        path: 'cashbook',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/cashbook/cashbook.module').then(m => m.CashbookModule),
        data: {
          oneOfRoles: ['Kassabuch'],
          features: ['Kassabuch']
        }
      },
      {
        path: 'temperature-logger',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/temperature-logger/temperature-logger.module').then(m => m.TemperatureLoggerModule),
        data: {
          oneOfRoles: ['Temperatur Logger'],
          features: ['TemperaturLogger']
        }
      },
      {
        path: 'last-chance-articles',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/last-chance-articles/last-chance-articles.module').then(m => m.LastChanceArticlesModule),
        data: {
          oneOfRoles: ['Noch verfügbare Artikel'],
          features: ['LastChanceArticles']
        }
      },
      {
        path: 'drug-book',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/drug-book/drug-book.module').then(m => m.DrugBookModule),
        data: {
          roles: ['Suchtgiftbuch'],
          features: ['DrugBook']
        }
      },
      {
        path: 'med-test',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/med-test/med-test.module').then(m => m.MedTestModule),
        data: {
          roles: ['Arzneitmittelprüfung'],
          features: ['MedTest']
        }
      },
      {
        path: 'enrollment',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/main-menu/main-menu.module').then(m => m.MainMenuModule),
        data: {
          oneOfRoles: ['Preissenkung', 'Beipackanmeldung'],
          features: ['Preissenkung', 'Beipack']
        }
      },
      {
        path: 'analytics',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde, AppGuardsFeatureguard],
        loadChildren: () => import('./modules/main-menu/main-menu.module').then(m => m.MainMenuModule),
        data: {
          oneOfRoles: [
            'Dokument Chargendokumentation',
            'Dokumentensuche',
            'Auftragsarchiv',
            'Rueckstaende',
            'Lieferapotheke',
            'Statistiken - Umsatz Gemischt',
            'Statistiken - Umsatz Nur Hochpreis',
            'Statistiken - Umsatz Ohne Hochpreis',
            'Statistiken - Umsatz Import',
            'Statistiken - Umsatz Magistral',
            'Statistiken - Umsatz Emballagen',
            'Statistiken - Umsatz Problemartikel'
          ],
          features: ['ChargenDokumentation', 'Dokumentensuche', 'Auftragsarchiv', 'Rueckstaende', 'Lieferapotheke', 'Statistiken']
        }
      },
      {
        path: 'dashboard',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'shop',
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        canActivateChild: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule),
        data: {
          oneOfRoles: ['Auftrag']
        }
      },
      {
        path: 'admin',
        canActivate: [AppGuardsAuthguard],
        canActivateChild: [AppGuardsAuthguard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'notifications',
        component: AppNotificationListComponent,
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        data: {
          oneOfRoles: [
            'User'
          ]
        }
      },
      {
        path: 'settings',
        component: AppSettingsComponent,
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        data: {
          oneOfRoles: [
            'User'
          ]
        }
      },
      {
        path: 'customer-settings',
        component: AppCustomerSettingsComponent,
        canActivate: [AppGuardsAuthguard, AppGuardsCondition, AppGuardsHasLieferkunde],
        data: {
          oneOfRoles: [
            'Kunde'
          ]
        }
      },
      {
        path: 'lieferkunden',
        component: AppLieferkundeSelectorComponent
      },
      {
        path: 'lieferkunde',
        component: AppLieferkundeSelectorComponent,
        data: {
          differential_mode: true
        }
      },
      {
        path: 'index_app.html',
        redirectTo: ''
      },
    ],
    data: {
      oneOfRoles: ['User']
    }
  },
  {
    path: '',
    component: AppLayoutsLoginComponent,
    children: [
      {
        path: 'logout',
        component: AppUserLogoutComponent,
        canActivate: [AppGuardsAuthguard],
      },
      {
        path: 'login',
        component: AppLoginComponent
      },
      {
        path: 'reset-password',
        component: AppResetPasswordComponent,
      },
      {
        path: 'validate-ip/:token',
        component: AppValidateIpComponent,
      },
      {
        path: 'reset-password/:token',
        component: AppResetPasswordComponent,
      },
      {
        path: 'register',
        component: AppRegisterComponent
      },
      {
        path: 'docs',
        loadChildren: () => import('./modules/docs/docs.module').then(m => m.DocsModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, private cfg: AppConfigService, private loader: AppPageloaderService) {
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.loader.stopAll();
        this.loader.start('Router');

      } else if (e instanceof NavigationEnd) {
        this.loader.stop('Router');

        const last: any = this.getDeepestChild(this.router.routerState.snapshot.root);

        if (last.component && last.component.id) {
          this.cfg.active_component = last.component.id;
        } else {
          this.cfg.active_component = 'Unknown';
        }

        this.cfg.active_route = e.urlAfterRedirects;
      } else if (e instanceof NavigationCancel || e instanceof NavigationError) {
        this.loader.stop('Router');
      }
    });
  }

  getDeepestChild(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (snapshot.children.length > 0) {
      return this.getDeepestChild(snapshot.children[0]);
    } else {
      return snapshot;
    }
  }
}
