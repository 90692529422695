import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {Injectable} from '@angular/core';
import {AppDialogsCustomdialogComponent} from '../dialogs/customdialog/app.dialogs.customdialog.component';
import {Notification} from '../models/notification';
import {Formbuilder} from '../dialogs/form/formbuilder';
import {File} from "../models/file";
import {
  SharedDialogsContentViewerComponent
} from "../modules/shared/dialogs/content-viewer/shared.dialogs.content-viewer.component";

@Injectable({providedIn: 'root'})
export class AppDialogsService {
  constructor(private dialog: MatDialog) {
  }

  previewFile(file: File, blob: Blob) {
    this.dialog.open(SharedDialogsContentViewerComponent, {
      data: {
        file: file,
        blob: blob,
      },
      width: 'calc(70vw + 50px)',
      minWidth: 'calc(70vw + 50px)',
    })
  }

  confirm(icon: string, title: string, question: string, on_ok: any = null,
          on_nok: any = null): MatDialogRef<AppDialogsCustomdialogComponent> {
    if (icon === null || icon == '') {
      icon = 'frage';
    }
    const dialog = this.dialog.open(AppDialogsCustomdialogComponent, {
      data: {
        icon: icon,
        title: title,
        text: question,
        buttons: [
          {
            id: 'ok',
            label: 'Ja',
            class: 'bgprimary'
          },
          {
            id: 'nok',
            label: 'Nein'
          }
        ]
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result == 'ok' && on_ok) {
        on_ok();

      } else {
        if (on_nok) {
          on_nok();
        }
      }
    });

    return dialog;

  }

  ask_survey(title: string, question: string, on_ok: any = null, on_postpone1: any = null, on_postpone2: any = null,
             on_nok: any = null): MatDialogRef<AppDialogsCustomdialogComponent> {
    const dialog = this.dialog.open(AppDialogsCustomdialogComponent, {
      data: {
        icon: 'warning',
        title: title,
        text: question,
        buttons: [
          {
            id: 'ok',
            label: 'Ja',
            class: 'bgprimary'
          },
          {
            id: 'nok',
            label: 'Nein'
          },
          {
            id: 'postpone1',
            label: 'Später erinnern',
            class: 'bgsecondary'
          },
          {
            id: 'postpone2',
            label: 'Morgen erinnern',
            class: 'bgsecondary'
          }
        ]
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result == 'ok' && on_ok) {
        on_ok();

      } else if (result == 'postpone1' && on_postpone1) {
        on_postpone1();

      } else if (result == 'postpone2' && on_postpone2) {
        on_postpone2();

      } else if (result == 'nok' && on_nok) {
        on_nok();
      }
    });

    return dialog;

  }


  info(icon: string, title: string, text: string, on_close: any = null): MatDialogRef<AppDialogsCustomdialogComponent> {
    if (icon === null || icon == '') {
      icon = 'info';
    }
    const dialog = this.dialog.open(AppDialogsCustomdialogComponent, {
      data: {
        icon: icon,
        title: title,
        text: text,
        buttons: [
          {
            id: 'ok',
            label: 'OK',
            class: 'bgprimary'
          },
        ]
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (on_close) {
        on_close();
      }

    });

    return dialog;
  }

  notification(notification: Notification, on_seen: any): MatDialogRef<AppDialogsCustomdialogComponent> {

    const buttons = [];

    if (notification.rendered.url) {
      buttons.push({
        id: 'seen',
        label: 'Anzeigen',
        class: 'bgprimary'
      });

    } else {
      buttons.push({
        id: 'seen',
        label: 'Gesehen',
        class: 'bgprimary'
      });
    }


    const dialog = this.dialog.open(AppDialogsCustomdialogComponent, {
      disableClose: !notification.dismissable,
      data: {
        title: notification.rendered.label,
        icon: notification.rendered.icon,
        text: notification.rendered.text,
        buttons: buttons
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result === 'seen') {
        on_seen(notification, true);
      } else if (notification.dismissable) {
        on_seen(notification, false);
      }
    });

    return dialog;
  }

  form() {
    return new Formbuilder(this.dialog);
  }
}
