<h1 mat-dialog-title>
  <table>
    <tr>
      <td>
        <svg-icon>info</svg-icon>
      </td>
      <td>
        {{ data.titel }}
      </td>
    </tr>
  </table>
</h1>
<div mat-dialog-content style="min-height: 100px">
  <div style="font-size: 24px; padding: 20px; line-height: 28px;">
    {{data.msg}}
  </div>

  <div style="font-size: 18px; margin-top: 20px">
    Was wollen sie tun?
  </div>
</div>
<div mat-dialog-actions>
  <div style="text-align: center; width: 100%">
    <button style="margin: 5px" mat-flat-button (click)="setResponse('addAll')">
      Gesamtmenge bestellen
    </button>

    <button style="margin: 5px" mat-flat-button (click)="setResponse('split')" *ngIf="data.showOnlyMoeglich">
      Teilmenge bestellen
    </button>

    <button style="margin: 5px" mat-stroked-button (click)="setResponse('splitAndAlternative')" *ngIf="data.showSplitAlternativeArticleButton">
      Teilmenge bestellen und Alternativprodukte anzeigen
    </button>

    <button style="margin: 5px" mat-flat-button (click)="setResponse('alternative')" *ngIf="data.showAlternativeArticleButton">
      Alternativprodukte anzeigen
    </button>

    <button style="margin: 5px" mat-raised-button (click)="setResponse('cancel')">
      Abbrechen
    </button>
  </div>
</div>
