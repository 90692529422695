import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Artikel} from '../models/artikel';
import {AppEventService} from './app.event.service';
import {GuiMessage} from '../models/gui-message';
import {AppLieferkundeService} from './app.lieferkunde.service';
import {Lieferkunde} from '../models/lieferkunde';
import {Merkliste} from '../models/merkliste';
import {MerklistEntry} from '../models/merklist-entry';
import {AppPageloaderService} from './app.pageloader.service';
import {map} from 'rxjs/operators';
import {AppSettingsService} from './app.app_settings.service';
import setPrototypeOf = Reflect.setPrototypeOf;
import {PaginatedResult} from "../models/paginated-result";
import {AppUserService} from "./app.user.service";
import {DownloadedFile} from "../models/downloaded-file";

@Injectable({providedIn: 'root'})
export class AppMerklisteService implements OnDestroy {
  merklisten = new BehaviorSubject<Merkliste[]>([]);
  merklisten$ = this.merklisten.asObservable();

  primary_merkliste = new BehaviorSubject<Merkliste>(null);
  primary_merkliste$ = this.primary_merkliste.asObservable();

  lieferkunde: Lieferkunde;

  pmlid;

  pmlsub: Subscription;
  esub: Subscription;

  constructor(private http: HttpClient, private cfg: AppConfigService, private esvc: AppEventService, private usvc: AppUserService,
              private lksvc: AppLieferkundeService, private loader: AppPageloaderService, private settsvc: AppSettingsService) {
    this.lksvc.current_lieferkunde$.subscribe(lk => {
      if (
        (!this.lieferkunde && lk) ||
        (this.lieferkunde && !lk) ||
        (this.lieferkunde && lk && this.lieferkunde.LieferkundeNr != lk.LieferkundeNr)
      ) {
        this.lieferkunde = lk;
        if (this.lieferkunde) {
          this.load();
        }
      }
    });
    this.esub = esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\MerklistEngine\\MerklistChangedEvent') {
        this.load();
      }
    });
    this.pmlsub = this.primary_merkliste.subscribe(ml => {
      if (ml) {
        if (this.pmlid !== 0 && ml.id != this.pmlid) {
          this.settsvc.setUserSettingValue('GUI_PrimaryMerklisteId', this.pmlid).subscribe();
        }

        this.pmlid = ml.id;
      }
    });
    this.settsvc.getUserSettingValue('GUI_PrimaryMerklisteId').subscribe(v => {
      this.pmlid = v;
      this.init_primary();

    });
  }

  ngOnDestroy(): void {
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
    if (this.pmlsub instanceof Subscription) {
      this.pmlsub.unsubscribe();
    }
  }

  init_primary() {
    if (this.merklisten.value && this.pmlid !== undefined) {
      let found = false;
      this.merklisten.value.forEach(ml => {
        if (ml.id == this.pmlid) {
          this.primary_merkliste.next(ml);
          found = true;
        }
      });

      if (!found) {
        this.primary_merkliste.next(this.merklisten.value[0]);
      }
    }
  }

  load() {
    if (this.usvc.isLoggedin) {
      this.loader.start('AppMerklisteService_1');
      this.getMerklisten().subscribe(mls => {
        this.merklisten.next(mls);
        if (this.primary_merkliste.getValue() === null || this.primary_merkliste.getValue() === undefined) {
          this.init_primary();
        } else {
          const id = this.primary_merkliste.getValue().id;
          mls.forEach(ml => {
            if (ml.id == id) {
              this.primary_merkliste.next(ml);
            }
          });
        }
        this.loader.stop('AppMerklisteService_1');
      });
    } else {
      this.merklisten.next([]);
      this.primary_merkliste.next(null);
    }
  }

  createMerkliste(merklist: Merkliste): Observable<Merkliste> {
    return this.http.post<Merkliste>(
      this.cfg.buildUrl('/merklist/add'),
      {
        merklist: merklist
      }
    ).pipe(map(wk => {
      this.protoMerkliste(wk);
      return wk;
    }));
  }

  deleteMultiplePositions(merklistId, entryIds): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/merklist/'+merklistId+'/deleteMultiple'),
      {
        entryIds: entryIds,
      }
    );
  }

  copyEntriesToOtherMerklist(merklistIdFrom, entryIds, merklistIdTo): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/merklist/'+merklistIdFrom+'/copyOrMove'),
      {
        entryIds: entryIds,
        targetId: merklistIdTo,
        isCopy: true
      }
    );
  }

  moveEntriesToOtherMerklist(merklistIdFrom, entryIds, merklistIdTo): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/merklist/'+merklistIdFrom+'/copyOrMove'),
      {
        entryIds: entryIds,
        targetId: merklistIdTo,
        isCopy: false
      }
    );
  }

  updateMerkliste(merklist: Merkliste): Observable<Merkliste> {
    return this.http.post<Merkliste>(
      this.cfg.buildUrl('/merklist/' + merklist.id + '/update'),
      {
        merklist: merklist
      }
    ).pipe(map(wk => {
      this.protoMerkliste(wk);
      return wk;
    }));
  }

  deleteMerkliste(merklist: Merkliste): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/merklist/' + merklist.id + '/delete'),
      {}
    );
  }

  emptyMerkliste(merklist: Merkliste): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/merklist/' + merklist.id + '/empty'),
      {}
    );
  }

  getMerklisten(): Observable<Merkliste[]> {
    return this.http.get<Merkliste[]>(
      this.cfg.buildUrl('/merklist')
    ).pipe(map(wks => {
      wks.forEach(wk => {
        this.protoMerkliste(wk);
      });
      return wks;
    }));
  }

  getMerklisteById(id): Observable<Merkliste> {
    return this.http.get<Merkliste>(
      this.cfg.buildUrl('/merklist/' + id)
    ).pipe(map(wk => {
      this.protoMerkliste(wk);
      return wk;
    }));
  }

  printMerklisteById(id): Observable<DownloadedFile> {
    return this.http.get(
      this.cfg.buildUrl('/merklist/' + id + '/print'),
      {
        responseType: 'blob',
        observe: 'response'
      }
    ).pipe(map((resp) => {
      const contentDisposition = resp.headers.get('Content-Disposition');
      const contentType = resp.headers.get('Content-Type');
      let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      filename = filename.replace(new RegExp('"', 'g'), '');

      return new DownloadedFile(filename, contentType, resp.body);
    }));
  }

  getMerklisteEntriesById(id, search, page, limit): Observable<PaginatedResult<MerklistEntry>> {
    return this.http.post<PaginatedResult<MerklistEntry>>(
      this.cfg.buildUrl('/merklist/' + id + '/entries'),
      {
        search: search,
        page: page,
        limit: limit
      }
    ).pipe(map(res => {
      res.data.forEach(e => {
        this.protoMerklisteEntry(e);
      })

      return res;
    }));
  }

  addToMerklisteById(id, view_slug, artikelid, menge): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/merklist/' + id + '/add'),
      {
        view_slug: view_slug,
        artikelid: artikelid,
        menge: menge,
      }
    );
  }

  deleteFromMerklisteById(id, posid): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/merklist/' + id + '/' + posid + '/delete'),
      {}
    );
  }

  duplicateFromMerklisteById(id, posid): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/merklist/' + id + '/' + posid + '/duplicate'),
      {}
    );
  }

  updateForMerklisteById(id, entry: MerklistEntry): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/merklist/' + id + '/' + entry.id + '/update'),
      {
        entry: entry
      }
    );
  }

  private protoMerkliste(wk: Merkliste) {
    if (wk) {
      setPrototypeOf(wk, Merkliste.prototype);

      if (wk.entries) {
        wk.entries.forEach(e => {
          setPrototypeOf(e, MerklistEntry.prototype);

          if (e.artikel) {
            setPrototypeOf(e.artikel, Artikel.prototype);
          }
        });
      }
    }
  }

  private protoMerklisteEntry(e: MerklistEntry) {
    setPrototypeOf(e, MerklistEntry.prototype);

    if (e.artikel) {
      setPrototypeOf(e.artikel, Artikel.prototype);
    }
  }
}
