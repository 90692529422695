<h1 mat-dialog-title>
  <table>
    <tr>
      <td>
        <svg-icon>info</svg-icon>
      </td>
      <td>
        <ng-container *ngIf="data.state.inOrdersAmount > 0">Bereits bestellt!</ng-container>
        <ng-container *ngIf="data.state.inOrdersAmount == 0 && data.state.inOtherWkAmount > 0">Bereits in einem Warenkorb!</ng-container>
        <ng-container *ngIf="data.state.inOrdersAmount == 0 && data.state.inOtherWkAmount == 0 && data.state.inCurrentWkAmount > 0">Bereits im Warenkorb!</ng-container>
      </td>
    </tr>
  </table>
</h1>
<div mat-dialog-content style="min-height: 100px">
  <div style="font-size: 24px; padding: 20px; line-height: 28px;">
    <div style="margin-bottom: 5px" *ngIf="data.state.inOrdersAmount > 0">Es wurde{{ data.state.inOrdersAmount>1?'n':'' }} heute bereits <span style="font-weight: bold">{{ data.state.inOrdersAmount }} Stück</span> bestellt!</div>
    <div style="margin-bottom: 5px" *ngIf="data.state.inOtherWkAmount > 0">Es befinde{{ data.state.inOtherWkAmount>1?'n':'t' }} sich bereits <span style="font-weight: bold">{{ data.state.inOtherWkAmount }} Stück</span> in einem anderen Warenkorb!</div>
    <div style="margin-bottom: 5px" *ngIf="data.state.inCurrentWkAmount > 0"><span style="font-weight: bold">{{data.state.inCurrentWkAmount}} Stück</span> {{ data.state.inCurrentWkAmount > 1 ? 'sind':'ist' }} bereits im Warenkorb!</div>
  </div>

  <div style="font-size: 18px; margin-top: 20px">
    Was wollen sie tun?
  </div>
</div>
<div mat-dialog-actions>
  <div style="text-align: center; width: 100%">
    <button style="margin: 5px" mat-flat-button *ngIf="data.state.inCurrentWkAmount > 0" (click)="setResponse('raiseAmount', data.amount)" matTooltip="Menge im Warenkorb erhöhen">
      Menge auf {{data.state.inCurrentWkAmount + data.amount}} erhöhen
    </button>

    <button style="margin: 5px" mat-stroked-button *ngIf="data.state.inCurrentWkAmount > 0" (click)="setResponse('setAmount', data.amount - data.state.inCurrentWkAmount)" matTooltip="Menge für den Artikel im Warenkorb übernehmen">
      Menge von {{data.amount}} übernehmen
    </button>

    <button style="margin: 5px" mat-flat-button *ngIf="data.state.inCurrentWkAmount == 0" (click)="setResponse('setAmount', data.amount)">
      Trotzdem in den Warenkorb legen
    </button>

    <button style="margin: 5px" mat-raised-button (click)="setResponse('cancel', 0)">
      Abbrechen
    </button>
  </div>
</div>
