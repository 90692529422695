import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild, ViewChildren
} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {AppEventService} from '../../../../services/app.event.service';
import {DomainVertreterConfig} from '../../../../models/domain-vertreter-config';
import {BehaviorSubject, Subject} from 'rxjs';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {Warenkorb} from '../../../../models/warenkorb';
import {View} from "../../../../models/view";
import {AppDialogsService} from "../../../../services/app.dialogs.service";
import {ShopViewComponent} from "../view/shop.view.component";
import {takeUntil} from "rxjs/operators";
import {AppUserService} from "../../../../services/app.user.service";
import {
  SharedNiceNumberInputComponent
} from "../../../shared/components/nice-number-input/shared.nice-number-input.component";
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";
import {ShopViewInterface} from "../../interfaces/shop.view-interface";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'shop-entry-edit-fields',
  templateUrl: 'shop.warenkorb-entry-edit-fields.component.html',
  styleUrls: ['shop.warenkorb-entry-edit-fields.component.scss']
})
export class ShopWarenkorbEntryEditFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() artikel: Artikel;
  @Input() view: View;
  @Input() viewcomp: ShopViewInterface;
  @Input() vertreterconfig: BehaviorSubject<DomainVertreterConfig>;
  @Input() disabled = false;
  @Input() disableDialog = false;
  @Input() entry: WarenkorbEntry | any;
  @Output() entryChange = new EventEmitter<WarenkorbEntry>();
  @Input() warenkorb: Warenkorb;
  @Input() view_only = false;
  @Input() islastavailable = false;
  @Output() mengeKeydownEnter = new EventEmitter<boolean>();

  editEntry: WarenkorbEntry | any;

  @ViewChild('mengeinput') mengeinput: ElementRef;

  @ViewChild('inputFreimenge') inputFreimenge: SharedNiceNumberInputComponent;
  @ViewChild('inputNaturalrabattmenge') inputNaturalrabattmenge: SharedNiceNumberInputComponent;
  @ViewChild('inputAktionsrabattmenge') inputAktionsrabattmenge: SharedNiceNumberInputComponent;
  @ViewChild('inputAktionsrabattprozent') inputAktionsrabattprozent: SharedNiceNumberInputComponent;
  @ViewChild('inputBasisverrechnungspreis') inputBasisverrechnungspreis: SharedNiceNumberInputComponent;
  @ViewChild('inputBasisverrechnungspreisprozent') inputBasisverrechnungspreisprozent: SharedNiceNumberInputComponent;
  @ViewChild('inputMenge') inputMenge: SharedNiceNumberInputComponent;

  has_change = false;
  _onDestroy = new Subject();
  focusElem: any;

  @ViewChildren(SharedNiceNumberInputComponent) niceNumberInputs: SharedNiceNumberInputComponent[];

  constructor(private cd: ChangeDetectorRef, private esvc: AppEventService, private svc: AppWarenkorbService,
              private dialog: AppDialogsService, private usvc: AppUserService) {
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entry) {
      this.init();
    }
    this.cd.markForCheck();
  }

  validate() {
    let valid = true;
    if (this.inputFreimenge && !this.inputFreimenge.valid) {
      valid = false;
    }
    if (this.inputNaturalrabattmenge && !this.inputNaturalrabattmenge.valid) {
      valid = false;
    }
    if (this.inputAktionsrabattmenge && !this.inputAktionsrabattmenge.valid) {
      valid = false;
    }
    if (this.inputAktionsrabattprozent && !this.inputAktionsrabattprozent.valid) {
      valid = false;
    }
    if (this.inputBasisverrechnungspreis && !this.inputBasisverrechnungspreis.valid) {
      valid = false;
    }
    if (this.inputBasisverrechnungspreisprozent && !this.inputBasisverrechnungspreisprozent.valid) {
      valid = false;
    }
    if (this.inputMenge && !this.inputMenge.valid) {
      valid = false;
    }
    this.editEntry.valid = valid;
  }

  focusMenge() {
    if (this.mengeinput) {
      this.mengeinput.nativeElement.focus();
    } else {
      setTimeout(() => {
        this.focusMenge();
      }, 50);
    }
  }

  mengeEnter() {
    AppAsyncTimeout.setTimeout(() => {
      this.mengeKeydownEnter.emit(true);
    }, 100);
  }

  reset() {
    this.detectChanges();
    this.niceNumberInputs.forEach(inp => {
      inp.detectChanges();
    })
  }

  change() {
    this.validate();

    this.has_change = true;

    if (isNaN(this.editEntry.freimenge)) {
      this.editEntry.freimenge = parseInt(this.editEntry.freimenge, 10);
    }
    if (isNaN(this.editEntry.menge)) {
      this.editEntry.menge = parseInt(this.editEntry.menge, 10);
    }
    this.editEntry.dirty = true;

    if (this.view && this.viewcomp && this.view.settings && this.view.settings.warning_above_amount) {
      if (this.editEntry.menge > this.view.settings.warning_above_amount) {
        this.viewcomp.showWarning(true);
      } else {
        this.viewcomp.showWarning(false);
      }
    }

    this._sendEntry();
  }

  _sendEntry() {
    this.entryChange.emit(this.editEntry);
    this.esvc.addGuiEvent('view_article_changed', this.artikel.ArtikelNummer);
  }

  init() {
    this.editEntry = JSON.parse(JSON.stringify(this.entry));
    Object.setPrototypeOf(this.editEntry, WarenkorbEntry.prototype);
    this.editEntry.artikel = this.entry.artikel;

    let need_update = false;
    if (!(this.editEntry instanceof WarenkorbEntry)) {
      this.editEntry = new WarenkorbEntry();
      this.editEntry.freimenge = 0;
      this.editEntry.menge = 1;
      need_update = true;
    }


    this.editEntry.artikel = this.artikel;
    if (this.editEntry.basisverrechnungspreis == 0) {
      this.editEntry.basisverrechnungspreis = this.artikel.Kundeneinkaufspreis;
      need_update = true;
    }

    if (['NE', 'AV', 'NG'].includes(this.artikel.VerfuegbarkeitsstatusCode)) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }

    if (this.islastavailable) {
      this.disabled = false;
    }

    if (this.view_only) {
      this.disabled = true;
    }

    let origValid = this.editEntry.valid;

    this.validate();

    if (this.editEntry.valid !== origValid) {
      need_update = true;
    }

    if (need_update) {
      this._sendEntry();
    }
  }

  ngOnInit(): void {
    this.init();

    this.esvc.getQueue().pipe(takeUntil(this._onDestroy)).subscribe(e => {
      if (e.name == 'gui.setWkEntryArtikelMenge' && e.data.artnr == this.artikel.ArtikelNummer) {
        this.editEntry.menge = e.data.menge;
        this._sendEntry();
      }
    });

  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
}
