import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppConfigService} from "./app.config.service";
import {Observable} from "rxjs";
import {ArtikelKlassifizierungTyp} from "../models/artikel-klassifizierung-typ";
import {Artikel} from "../models/artikel";
import {PaginatedResult} from "../models/paginated-result";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class AppArticleService {
  constructor(private http: HttpClient, private cfg: AppConfigService) {
  }

  getKlassifizierungstypen(): Observable<ArtikelKlassifizierungTyp[]> {
    return this.http.get<ArtikelKlassifizierungTyp[]>('/shop/artikel/klassifizierungstypen')
  }

  getDefaultKlassifizierungstyp(): Observable<string> {
    return this.http.get<string>('/shop/artikel/klassifizierungstypen/default')
  }

  findAlternativprodukte(artikel: Artikel, system: string, onlyOnStock: boolean, page, limit): Observable<PaginatedResult<Artikel>> {
    return this.http.post<PaginatedResult<Artikel>>('/shop/artikel/'+system+'/'+artikel.ArtikelNummer,
      {
        onlyOnStock: onlyOnStock,
        page: page,
        limit: limit,
      }
    ).pipe(map(ret => {
      Object.setPrototypeOf(ret, PaginatedResult.prototype);

      if(ret.data) {
        ret.data.forEach(a => {
          Object.setPrototypeOf(a, Artikel.prototype);
        })
      }

      return ret;
    }));
  }
}
