<mat-tab-group *ngIf="groups">
  <mat-tab *ngFor="let group of groups" label="{{group.name}}">
    <div class="settingscontent">
      <div *ngFor="let sgrp of group.groups">
        <div class="settingscontent-sub-header" *ngIf="group.groups.length > 1">{{sgrp.name}}</div>

        <div [class.settingscontent-sub]="group.groups.length > 1">
          <div *ngFor="let e of sgrp.entries; let last = last" [class.border_bottom]="!last"
               style="padding-bottom: 20px;">
            <div *ngIf="e.isAction">
              <div>
                <button (click)="onClick(e.id)" mat-raised-button>
                  {{e.name}}
                </button>
              </div>
              <mat-hint *ngIf="e.description">{{e.description}}</mat-hint>
            </div>

            <mat-form-field appearance="outline" style="width: 400px"
              *ngIf="e.choices && !(e.choices.length == 2 && ( (e.choices[0] == true && e.choices[1] == false) || (e.choices[0] == false && e.choices[1] == true) ))">
              <mat-label>{{e.name}}</mat-label>
              <mat-select (selectionChange)="changeData(e.id)" [formControl]="fcontrols[e.id]" placeholder="{{e.name}}"
                          [multiple]="e.multiple">
                <ng-container *ngFor="let c of e.choices">
                  <mat-option *ngIf="c.value" [value]="c.value">{{c.label}}</mat-option>
                  <mat-option *ngIf="!c.value" [value]="c">{{c}}</mat-option>
                </ng-container>

              </mat-select>
              <mat-hint *ngIf="e.description">{{e.description}}</mat-hint>
              <mat-error>Ungültiger Wert!</mat-error>
            </mat-form-field>

            <div
              *ngIf="e.choices && (e.choices.length == 2 && ( (e.choices[0] == true && e.choices[1] == false) || (e.choices[0] == false && e.choices[1] == true) ))">
              <div>
                <mat-checkbox (change)="changeData(e.id)" [formControl]="fcontrols[e.id]">
                  {{e.name}}
                </mat-checkbox>
              </div>
              <mat-hint *ngIf="e.description">{{e.description}}</mat-hint>
            </div>

            <mat-form-field *ngIf="!e.isPassword && !e.isAction && !e.choices && !e.special_type" appearance="outline"
                            style="width: 300px; max-width: 100vw;">
              <mat-label>{{e.name}}</mat-label>
              <input (keyup)="changeData(e.id)" [formControl]="fcontrols[e.id]" matInput>
              <mat-hint *ngIf="e.description">{{e.description}}</mat-hint>
              <mat-error>Ungültiger Wert!</mat-error>

            </mat-form-field>

            <mat-form-field *ngIf="!e.isPassword && !e.isAction && !e.choices && e.special_type == 'textarea'"  appearance="outline"
                            style="width: 600px; max-width: 100vw;">
              <mat-label>{{e.name}}</mat-label>
              <textarea (keyup)="changeData(e.id)" [formControl]="fcontrols[e.id]" matInput rows="50"></textarea>
              <mat-hint *ngIf="e.description">{{e.description}}</mat-hint>
              <mat-error>Ungültiger Wert!</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="!e.isPassword && !e.isAction && !e.choices && e.special_type == 'color'" appearance="outline"
                            style="width: 600px; max-width: 100vw;">
              <mat-label>{{e.name}}</mat-label>
              <input matInput [ngxMatColorPicker]="picker" [formControl]="fcontrols[e.id]"
                     placeholder="{{e.name}}"
                     (colorChange)="changeData(e.id, null, e)">
              <span matSuffix>
                <button mat-icon-button (click)="clear(e.id)">
                  <svg-icon size="12">abbrechen</svg-icon>
                </button>
              </span>
              <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
              <ngx-mat-color-picker #picker [touchUi]="true"></ngx-mat-color-picker>
              <mat-hint *ngIf="e.description">{{e.description}}</mat-hint>
              <mat-error>Ungültiger Wert!</mat-error>
            </mat-form-field>

            <file-picker *ngIf="!e.isPassword && !e.isAction && !e.choices && e.special_type == 'file'" [file]="e.value"
                         [placeholder]="e.name"
                         (fileChange)="changeData(e.id, $event)" style="width: 300px; max-width: 100vw;">
            </file-picker>

            <user-ips *ngIf="!e.isPassword && !e.isAction && !e.choices && e.special_type == 'user-ips'"
                      style="width: 300px; max-width: 100vw;">
            </user-ips>

            <mat-form-field *ngIf="e.isPassword" style="width: 300px; max-width: 100vw;" appearance="outline">
              <input (keyup)="changeData(e.id)" [formControl]="fcontrols[e.id]" matInput placeholder="{{e.name}}"
                     type="password">
              <mat-hint *ngIf="e.description">{{e.description}}</mat-hint>
              <mat-error>Ungültiger Wert!</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
