import {Injectable} from '@angular/core';
import {
  ShopDialogsVertreterAddToWarenkorbComponent
} from '../modules/shop/dialogs/vertreter-add-to-warenkorb/shop.dialogs.vertreter-add-to-warenkorb.component';
import {Warenkorb} from '../models/warenkorb';
import {WarenkorbActionInfo} from '../models/warenkorb-action-info';
import {
  ShopWarenkorbActionInfoComponent
} from '../modules/shop/components/warenkorb-action-info/shop.warenkorb-action-info.component';
import {MatDialog} from '@angular/material/dialog';
import {AppWarenkorbService} from './app.warenkorb.service';
import {WarenkorbEntry} from '../models/warenkorb-entry';
import {AppPageloaderService} from './app.pageloader.service';
import {AppViewService} from './app.view.service';
import {AppDialogsService} from './app.dialogs.service';
import {MerklistEntry} from "../models/merklist-entry";
import {
  ShopDialogsNachliefernMultiFrageComponent
} from "../modules/shop/dialogs/nachliefern-multi-frage/shop.dialogs.nachliefern-multi-frage.component";
import {AppSnackbarService} from "./app.snackbar.service";
import {Nachfolgeartikel} from "../models/nachfolgeartikel";
import {
  ShopDialogsTryaddArtToWkConfirmComponent
} from "../modules/shop/dialogs/tryadd-art-to-wk-confirm/shop.dialogs.tryadd-art-to-wk-confirm.component";
import {AppEventService} from "./app.event.service";
import {
  ShopDialogsVerfuegbarkeitsCheckConfirmComponent
} from "../modules/shop/dialogs/verfuegbarkeits-check-confirm/shop.dialogs.verfuegbarkeits-check-confirm.component";
import {
  ShopDialogsAlternativArtikelSucheComponent
} from "../modules/shop/dialogs/alternativ-artikel-suche/shop.dialogs.alternativ-artikel-suche.component";
import {AppConfigService} from "./app.config.service";

@Injectable({providedIn: 'root'})
export class AppShopService {

  wkkref: any;

  constructor(private mdsvc: MatDialog, private wksvc: AppWarenkorbService, private loader: AppPageloaderService,
              private snackBarSvc: AppSnackbarService, private vsvc: AppViewService, private dsvc: AppDialogsService,
              private esvc: AppEventService, private cfg: AppConfigService) {
  }

  addToPrimaryWarenkorb(entry: WarenkorbEntry | MerklistEntry, exec_after_add: any = null) {
    return this.addToWarenkorb(this.wksvc.primary_warenkorb.value, entry, exec_after_add);
  }

  _checkEntriesMessages(warenkorb: Warenkorb, toAddEntries: WarenkorbEntry[] | MerklistEntry[], messages: { title: string, msg: string, entry: WarenkorbEntry | MerklistEntry }[], exec_after_add: any = null) {
    if (messages && messages.length > 0) {
      let dialog = this.mdsvc.open(ShopDialogsNachliefernMultiFrageComponent,
        {
          data: messages
        });

      dialog.afterClosed().subscribe(entries => {
        if (entries) {
          entries.forEach(e => {
            toAddEntries.push(e);
          });

          this.do_addToWarenkorbMulti(warenkorb, toAddEntries, exec_after_add);
        }
      });
    } else {
      this.do_addToWarenkorbMulti(warenkorb, toAddEntries, exec_after_add);
    }
  }

  addToWarenkorbDialog(entry: WarenkorbEntry | MerklistEntry) {
    const ref = this.mdsvc.open(ShopDialogsVertreterAddToWarenkorbComponent, {
      data: entry
    });
    ref.afterClosed().subscribe(d => {
    });
  }

  addMultiToPrimaryWarenkorb(entries: WarenkorbEntry[] | MerklistEntry[], exec_after_add: any = null) {
    return this.addMultiToWarenkorb(this.wksvc.primary_warenkorb.value, entries, exec_after_add);
  }

  addMultiToWarenkorb(warenkorb: Warenkorb, entries: WarenkorbEntry[] | MerklistEntry[], exec_after_add: any = null) {
    const l = this.loader.start();

    let checkEntries = [];
    let toAddEntries = [];

    entries.forEach(entry => {
      if (entry.artikel.isVFStatusPruefbar()) {
        checkEntries.push(entry);
      } else {
        toAddEntries.push(entry);
      }
    });

    let checkDataEntry = [];
    let checkData = [];
    checkEntries.forEach(e => {
      let menge = 0;
      // tslint:disable-next-line:radix
      menge += parseInt(String(e.menge));
      // tslint:disable-next-line:radix
      menge += parseInt(String(e.freimenge));
      checkData.push({
        view_slug: e.view_slug,
        artikelnummer: e.artikel.ArtikelNummer,
        artikelmenge: menge,
        artikelstatuscode: e.artikel.VerfuegbarkeitsstatusCode,
      });
      checkDataEntry.push(e);
    });

    if (checkData.length > 0) {
      this.vsvc.multiVerfuegbarkeitBySlug(checkData).subscribe({
        next: (responses) => {
          let messages: { title: string, msg: string, entry: any }[] = [];

          responses.forEach((s, idx) => {
            let menge = checkData[idx].artikelmenge;
            let entry: any = checkEntries[idx];

            let nur_teilmenge = false;
            let moegliche_menge = 0;

            if (s.Verfuegbarkeitsinformation) {
              s.Verfuegbarkeitsinformation.forEach(i => {
                if (i.LieferungMoeglich) {
                  moegliche_menge += i.Menge;
                }
                if (i.InfoText == 'Teilmenge lagernd') {
                  nur_teilmenge = true;
                }
              });
            }

            let artlabel = entry.artikel.ArtikelBezeichnung;
            if (!artlabel) {
              artlabel = entry.artikel.ArtikelName;
            }

            if (moegliche_menge < menge || nur_teilmenge) {
              let title = '<b>' + artlabel + ' - ' + entry.menge + 'Stk </b>';
              let msg = '';

              if (nur_teilmenge) {
                msg += 'Es ist nur eine Teilmenge sofort lieferbar!';

              } else if (moegliche_menge > 0 && moegliche_menge >= menge) {
                msg = 'Es sind nur ' + moegliche_menge + ' lieferbar!';

              } else {
                msg = 'Kann besorgt werden!';
              }

              messages.push({
                title: title,
                msg: msg,
                entry: entry
              });

            } else {
              toAddEntries.push(entry)
            }
          });

          this._checkEntriesMessages(warenkorb, toAddEntries, messages, exec_after_add);

          l.stop();
        },
        error: (e) => {
          l.stop();
        }
      });
    } else {
      toAddEntries.forEach(entry => {
        this._addToWarenkorbPhase3(warenkorb, entry, exec_after_add);
      })
    }
  }

  addToWarenkorb(warenkorb: Warenkorb, entry: WarenkorbEntry | MerklistEntry, exec_after_add: any = null) {
    const l = this.loader.start();

    let menge = 0;
    // tslint:disable-next-line:radix
    menge += parseInt(String(entry.menge));
    // tslint:disable-next-line:radix
    menge += parseInt(String(entry.freimenge));

    this.wksvc.tryAddToWarenkorbById(
      warenkorb.id,
      entry.view_slug,
      entry
    ).subscribe(state => {
      l.stop();
      if (state.inCurrentWkAmount == 0 && state.inOtherWkAmount == 0 && state.inOrdersAmount == 0) {
        this._addToWarenkorbPhase2(warenkorb, entry, exec_after_add);
      } else {
        let ref = this.mdsvc.open(ShopDialogsTryaddArtToWkConfirmComponent,
          {
            data: {
              state: state,
              amount: entry.menge,
            },
          }
        );

        ref.afterClosed().subscribe(resp => {
          if (resp.state == 'setAmount') {
            entry.menge = resp.amount;
            this._addToWarenkorbPhase2(warenkorb, entry, exec_after_add);

          } else if (resp.state == 'raiseAmount') {
            this._addToWarenkorbPhase2(warenkorb, entry, exec_after_add);

          } else {
            if (exec_after_add) {
              exec_after_add();
            }
          }
        })
      }
    });
  }

  _showAlternativeArticles(entry: WarenkorbEntry|MerklistEntry, wantedAmount = 0) {
    this.vsvc.getBySlug(entry.view_slug).subscribe(view => {
      this.mdsvc.open(
        ShopDialogsAlternativArtikelSucheComponent,
        {
          data: {
            artikel: entry.artikel,
            view: view,
            wantedAmount: wantedAmount
          },
          width: '99vw',
          minWidth: '99vw'
        }
      )
    })
  }

  _addToWarenkorbPhase2(warenkorb: Warenkorb, entry: WarenkorbEntry | MerklistEntry, exec_after_add: any = null) {
    const l = this.loader.start();

    let menge = 0;
    // tslint:disable-next-line:radix
    menge += parseInt(String(entry.menge));
    // tslint:disable-next-line:radix
    menge += parseInt(String(entry.freimenge));

    if (entry.artikel.isVFStatusPruefbar()) {
      this.vsvc.verfuegbarkeitBySlug(entry.view_slug, entry.artikel, menge).subscribe({
        next: (s) => {
          let nur_teilmenge = false;
          let moegliche_menge = 0;
          let not_allowed = false;
          let not_allowed_text = null;
          let nachfolgeArtikel: Nachfolgeartikel = null;

          if (s.Nachfolgeartikel) {
            nachfolgeArtikel = s.Nachfolgeartikel;
          }

          if (s.Verfuegbarkeitsinformation) {
            s.Verfuegbarkeitsinformation.forEach(i => {
              if (i.LieferungMoeglich) {
                moegliche_menge += i.Menge;
              }
              if (i.InfoText == 'Teilmenge lagernd') {
                nur_teilmenge = true;
              }
              if (i.InfoCode == 'AP') {
                not_allowed = true;
                not_allowed_text = i.InfoText;
              }
            });
          }

          let artlabel = entry.artikel.ArtikelBezeichnung;
          if (!artlabel) {
            artlabel = entry.artikel.ArtikelName;
          }

          if (nachfolgeArtikel) {
            this.dsvc.confirm(
              'warning',
              'Nachfolgeartikel verfügbar!',
              'Wollen Sie den Nachfolgeartikel <strong>'+nachfolgeArtikel.ArtikelName+'</strong> in den Warenkorb legen?<br><br>'+
              '<div style="text-align: center">EP: '+nachfolgeArtikel.Kundeneinkaufspreis+'&euro;</div>',
              () => {
                let nachfolgeEntry = new WarenkorbEntry();
                Object.assign(nachfolgeEntry, entry);
                nachfolgeEntry.artikel = nachfolgeArtikel;
                this._addToWarenkorbPhase3(warenkorb, nachfolgeEntry, exec_after_add);
              }
            );

          } else if (not_allowed) {
            this.dsvc.info(
              'error',
              'Sie haben leider Keine Berechtigung für diesen Artikel!',
              not_allowed_text
            );

          } else if (moegliche_menge < menge || nur_teilmenge) {
            let title = '';
            let msg = '';
            let showOnlyMoeglich = false;
            let showAlternativeArticleButton = false;
            let showSplitAlternativeArticleButton = false;

            if (nur_teilmenge) {
              title = 'Nicht vollständig lieferbar!';
              msg = 'Es ist nur eine Teilmenge sofort lieferbar! Trotzdem in den Warenkorb legen?';
              showAlternativeArticleButton = true;

            } else if (moegliche_menge > 0 && moegliche_menge <= menge) {
              title = 'Nicht vollständig lieferbar!';
              msg = 'Es sind nur ' + moegliche_menge + ' ' + entry.artikel.Packungseinheit + ' lieferbar! Trotzdem in den Warenkorb legen?';
              showAlternativeArticleButton = true;
              showSplitAlternativeArticleButton = true;
              showOnlyMoeglich = true;

            } else {
              title = 'Nicht auf Lager!';
              msg = 'Die gewünschte Menge von <hr><b>' +
                artlabel + ' - ' + entry.menge + 'Stk </b><hr> kann besorgt werden! Trotzdem in den Warenkorb legen?';
              showAlternativeArticleButton = true;
            }

            if (showAlternativeArticleButton || showSplitAlternativeArticleButton) {
              if (!this.cfg.featuresEnabled(["ArtikelKlassifizierungSuche"])) {
                showAlternativeArticleButton = false;
                showSplitAlternativeArticleButton = false;
              }
            }


            let dref = this.mdsvc.open(ShopDialogsVerfuegbarkeitsCheckConfirmComponent,
              {
                data: {
                  titel: title,
                  msg: msg,
                  showAlternativeArticleButton: showAlternativeArticleButton,
                  showSplitAlternativeArticleButton: showSplitAlternativeArticleButton,
                  showOnlyMoeglich: showOnlyMoeglich,
                }
              });

            dref.afterClosed().subscribe(ret => {
              if (ret == "addAll") {
                this._addToWarenkorbPhase3(warenkorb, entry, exec_after_add);

              } if (ret == "split") {
                  let entry2 = JSON.parse(JSON.stringify(entry));
                  entry2.menge = moegliche_menge;
                  this._addToWarenkorbPhase3(warenkorb, entry2, exec_after_add);

              } else if (ret == "splitAndAlternative") {
                let entry2: any = JSON.parse(JSON.stringify(entry));
                let leftMenge = entry2.menge - moegliche_menge
                entry2.menge = moegliche_menge;
                this._addToWarenkorbPhase3(warenkorb, entry2, exec_after_add);

                this._showAlternativeArticles(entry, leftMenge);

              } else if (ret == 'alternative') {
                this._showAlternativeArticles(entry, entry.menge);
              }
            });

          } else {
            this._addToWarenkorbPhase3(warenkorb, entry, exec_after_add);
          }
          l.stop();
        },
        error: (e) => {
          this.dsvc.confirm(
            'warning',
            'Verfügbarkeitsabfrage derzeit nicht möglich',
            'Trotzdem in den Warenkorb legen?',
            () => {
              this._addToWarenkorbPhase3(warenkorb, entry, exec_after_add);
            }
          );
          l.stop();
        }
      });
    } else {
      this._addToWarenkorbPhase3(warenkorb, entry, exec_after_add);
    }
  }

  private do_addToWarenkorbMulti(warenkorb: Warenkorb, entries: WarenkorbEntry[] | MerklistEntry[], exec_after_add: any = null) {
    const l = this.loader.start();

    if (warenkorb && entries.length > 0) {
      this.wksvc.addMultiToWarenkorbById(
        warenkorb.id,
        entries
      ).subscribe({
        next: (msgs) => {
          l.stop();

          entries.forEach(e => {
            this.esvc.addGuiEvent('shop_reset_entry_fields', {
              ArtikelNummer: e.artikel.ArtikelNummer
            });
          });

          msgs.forEach(m => {
            const i = new WarenkorbActionInfo();
            i.action = 'add';
            i.info = m;

            this.snackBarSvc.open(
              ShopWarenkorbActionInfoComponent,
              i,
              2500,
              );
          });

          if (exec_after_add) {
            exec_after_add();
          }

        }, error: () => {
          l.stop();
        }
      });
    } else if (entries.length == 0) {
      this.snackBarSvc.openMessage('Nichts hinzuzufügen!');
      l.stop();

    } else {
      this.snackBarSvc.openMessage('Fehlgeschlagen. Kein Warenkorb ausgewählt!');
      l.stop();
    }
  }

  private _addToWarenkorbPhase3(warenkorb: Warenkorb, entry: WarenkorbEntry | MerklistEntry, exec_after_add: any = null) {
    const l = this.loader.start();

    if (warenkorb) {
      this.wksvc.addToWarenkorbById(
        warenkorb.id,
        entry.view_slug,
        entry
      ).subscribe({
        next: (m) => {
          l.stop();

          this.esvc.addGuiEvent('shop_reset_entry_fields', {
            ArtikelNummer: entry.artikel.ArtikelNummer
          });

          const i = new WarenkorbActionInfo();
          i.action = 'add';
          i.info = m;

          if (exec_after_add) {
            exec_after_add();
          }

          this.snackBarSvc.open(ShopWarenkorbActionInfoComponent, i, 2500);
        }, error: () => {
          l.stop();
        }
      });
    } else {
      this.snackBarSvc.openMessage('Fehlgeschlagen. Kein Warenkorb ausgewählt!');
    }
  }
}
