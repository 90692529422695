<div class="shop-view-action" [class.in-row]="row_mode">
  <div class="shop-view-action-element">
    <shop-entry-edit-fields
      [artikel]="artikel"
      [view]="getView()"
      [viewcomp]="viewcomp"
      [vertreterconfig]="vertreterconfig"
      [(entry)]="entry"
      [islastavailable]="islastavailable"
      (mengeKeydownEnter)="mengeEnter()"
    >
    </shop-entry-edit-fields>
  </div>

  <div class="shop-view-action-element shop-view-action-element-no-margin">
    <warenkorb-button [artikel]="artikel" [disabled]="disabled" [entry]="entry"
                      [view_slug]="getViewSlug()"
                      [vertreterconfig]="vertreterconfig"
                      *ngIf="view && view.settings && (inDetailsView || !view.settings.bestellblock_mode)"
    ></warenkorb-button>
  </div>

  <div class="shop-view-action-element" hasPermission [allOfRoles]="['Vertreter', 'Kundensonderpreis Verwaltung']">
    <customer-special-price-edit-button [artikel]="artikel" [disabled]="disabled"></customer-special-price-edit-button>
  </div>

  <div class="shop-view-action-element">
    <verfuegbarkeits-info-button [artikel]="artikel" [disabled]="disabled" [entry]="entry"
                                 [view]="getView()" class="tut_shopview_artvf"></verfuegbarkeits-info-button>
  </div>

  <div class="shop-view-action-element" *ngIf="getView().artikelstamm == 1">
    <feature-block [features]="['ArtikelKlassifizierungSuche']">
      <alternativ-artikel-button [artikel]="artikel" [view]="getView()" class="tut_shopview_alternativ_button"></alternativ-artikel-button>
    </feature-block>
  </div>

  <div class="shop-view-action-element">
    <contact-button [artikel]="artikel" [view]="getView()" class="tut_shopview_contact" [asbutton]="true"></contact-button>
  </div>

  <div class="shop-view-action-element">
    <merkliste-button [artikel]="artikel" [disabled]="disabled" [entry]="entry" [view_slug]="getViewSlug()"
                      class="tut_shopview_artml"></merkliste-button>
  </div>
</div>
