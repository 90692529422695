import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {View} from '../../../../models/view';
import {Artikel} from '../../../../models/artikel';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {AppEventService} from '../../../../services/app.event.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {DomainVertreterConfig} from '../../../../models/domain-vertreter-config';
import {AppViewService} from '../../../../services/app.view.service';
import {AppUserService} from '../../../../services/app.user.service';
import {ShopViewComponent} from '../view/shop.view.component';
import {
  ShopWarenkorbEntryEditFieldsComponent
} from '../warenkorb-entry-edit-fields/shop.warenkorb-entry-edit-fields.component';
import {ShopWarenkorbButtonComponent} from "../warenkorb-button/shop.warenkorb-button.component";
import {takeUntil} from "rxjs/operators";
import {ShopViewInterface} from "../../interfaces/shop.view-interface";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'shop-view-action',
  templateUrl: 'shop.view-action.component.html',
  styleUrls: ['shop.view-action.component.scss']
})
export class ShopViewActionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() viewcomp: ShopViewInterface;
  @Input() view: View;
  @Input() artikel: Artikel;
  @Input() islastavailable = false;
  @Input() inDetailsView = false;

  @Input() row_mode = false;

  disabled = false;
  entry = new WarenkorbEntry();


  vcfgsub: Subscription;
  vertreterconfig: BehaviorSubject<DomainVertreterConfig> = new BehaviorSubject<DomainVertreterConfig>(null);

  @ViewChild(ShopWarenkorbEntryEditFieldsComponent) editcomp: ShopWarenkorbEntryEditFieldsComponent;
  @ViewChild(ShopWarenkorbButtonComponent) wkbutton: ShopWarenkorbButtonComponent;

  _onDestroy = new Subject<void>();

  constructor(private cd: ChangeDetectorRef, private esvc: AppEventService, private vsvc: AppViewService,
              public usvc: AppUserService) {
  }

  getView() {
    let view = this.view;

    if (this.artikel.view) {
      view = this.artikel.view;
    }

    return view;
  }

  getViewSlug() {
    let slug = this.view ? this.view.slug : null;

    if (this.artikel.view) {
      slug = this.artikel.view.slug;
    }

    return slug;
  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  focusMenge() {
    this.editcomp.focusMenge();
  }

  mengeEnter() {
    if (this.wkbutton) {
      this.wkbutton.addToPrimaryWarenkorb();
    } else {
      this.viewcomp.focusNextMenge(this);
    }
  }

  change() {
    this.esvc.addGuiEvent('view_article_changed', this.artikel.ArtikelNummer);
  }

  ngAfterViewInit(): void {
  }

  reset() {
    let entry = JSON.parse(JSON.stringify(this.entry));
    if (this.view.settings.bestellblock_mode) {
      entry.menge = 0;
    } else {
      entry.menge = 1;
    }
    entry.positionsart = 'Standard';
    entry.artikel = this.artikel;
    entry.view_slug = this.view.slug;
    entry.basisverrechnungspreis = this.artikel.Kundeneinkaufspreis;
    entry.freimenge = undefined;
    entry.aktionsrabattmenge = undefined;
    entry.naturalrabattmenge = undefined;
    entry.aktionsrabattprozent = undefined;
    entry.basisverrechnungspreisrabattprozent = 0;
    this.entry = entry;
    this.cd.markForCheck();
    this.change();
  }

  ngOnDestroy(): void {
    if (this.viewcomp) {
      this.viewcomp.unregisterViewActionComponent(this);
    }
    this._onDestroy.next();
    this._onDestroy.complete();
    if (this.vcfgsub instanceof Subscription) {
      this.vcfgsub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.esvc.getQueue().pipe(takeUntil(this._onDestroy)).subscribe(e => {
      if (e.name == 'gui.shop_reset_entry_fields' && e.data.ArtikelNummer == this.artikel.ArtikelNummer) {
        this.reset();
      }
    });
    if (this.viewcomp) {
      this.viewcomp.registerViewActionComponent(this);
    }
    this.vcfgsub = this.vsvc.$vertreterconfig.subscribe(c => {
      this.vertreterconfig.next(c);
    });
    if (this.view && this.view.settings && this.view.settings.bestellblock_mode) {
      this.entry.menge = 0;
      this.entry.freimenge = 0;
    } else {
      this.entry.menge = 1;
      this.entry.freimenge = 0;
    }
    this.entry.artikel = this.artikel;
    if (this.view) {
      this.entry.view_slug = this.view.slug;
    }
    this.entry.basisverrechnungspreis = this.artikel.Kundeneinkaufspreis;

    if (['NE', 'AV', 'NG'].includes(this.artikel.VerfuegbarkeitsstatusCode)) {
      this.disabled = true;
      /*} else if (this.artikel.IstArzneimittel && !this.artikel.HatBezugBerechtigung) {
        this.disabled = true;*/
    } else {
      this.disabled = false;
    }

    if (this.islastavailable) {
      this.disabled = false;
    }
  }
}
