export class PaginatedResult<T> {
  page: number;
  pages: number;
  count: number;
  data: T[];

  constructor() {
    this.page = 1;
    this.pages = 1;
    this.count = 0;
    this.data = [];
  }
}
