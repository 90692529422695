import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {NgModel} from "@angular/forms";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nice-number-input',
  templateUrl: 'shared.nice-number-input.component.html',
  styleUrls: ['shared.nice-number-input.component.scss']
})
export class SharedNiceNumberInputComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() class = '';
  @Input() tooltip = null;
  @Input() step = 1;
  @Input() min = null;
  @Input() max = null;
  @Input() inputSize = 2;
  @Input() nullable = false;

  @Input() value: any;
  @Output() valueChange = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() mengeKeydownEnter = new EventEmitter();

  focusOutLocked = false;
  hasFocus = new BehaviorSubject<boolean>(false);
  inputHasFocus = new BehaviorSubject<boolean>(false);

  focusLastVal: any;
  focusElem: any;

  valid = false;

  changeTimeout: any;
  focusOutTimeout: any;

  @ViewChild('inputNgModel') inputNgModel: NgModel;

  constructor(private cd: ChangeDetectorRef) {
  }

  detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    this.validate();
  }

  validate() {
    let valid = true;
    if (this.inputNgModel && !this.inputNgModel.valid) {
      valid = false;
    }
    if (valid) {
      if (isNaN(this.value) && !(this.value == null && this.nullable)) {
        valid = false;
      }
    }
    this.valid = valid;
  }

  _mengeEnter() {
    this.mengeKeydownEnter.emit(true);
  }

  cntPlus(step, max, ev = null) {
    this.focusOutLocked = true;

    if (ev) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
    }
    let vvar = this.value;
    if (vvar == '') {
      vvar = this.focusLastVal;
    }
    vvar = parseFloat(vvar);
    if (isNaN(vvar)) {
      vvar = 0;
    }
    if (max !== null) {
      if (vvar < max) {
        vvar += step;
      }
      if (vvar > max) {
        vvar = max;
      }
    } else {
      vvar += step;
    }
    if (vvar && !isNaN(vvar)) {
      if (step < 0.1) {
        vvar = vvar.toFixed(2);
      } else if (step < 1) {
        vvar = vvar.toFixed(1);
      } else {
        vvar.toFixed(0);
      }
    }
    this.focusLastVal = vvar;
    this.value = vvar;
    setTimeout(() => {
      this.focusOutLocked = false;
    }, 100);
    this._change();
  }

  cntMinus(step, min, ev = null) {
    this.focusOutLocked = true;

    if (ev) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
    }
    let vvar = this.value;
    if (vvar == '') {
      vvar = this.focusLastVal;
    }
    vvar = parseFloat(vvar);
    if (isNaN(vvar)) {
      vvar = 0;
    }
    if (min !== null) {
      if (vvar == min && this.nullable) {
        vvar = undefined;
      } else if (vvar > min) {
        vvar -= step;
      } else if (vvar < min) {
        vvar = min;
      }

    } else {
      vvar -= step;
    }
    if (vvar && !isNaN(vvar)) {
      if (step < 0.1) {
        vvar = vvar.toFixed(2);
      } else if (step < 1) {
        vvar = vvar.toFixed(1);
      } else {
        vvar.toFixed(0);
      }
    }
    this.focusLastVal = vvar;
    this.value = vvar;
    setTimeout(() => {
      this.focusOutLocked = false;
    }, 100);

    this._change();
  }

  mengeEnter() {
    setTimeout(() => {
      this.mengeKeydownEnter.emit(true);
    }, 100);
  }

  _inputChange() {
    this.focusLastVal = this.value
  }

  _changeTimeout() {
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }
    this.changeTimeout = setTimeout(() => {
      this._change();
    }, 100);
  }

  _change() {
    this.validate();
    if (this.valid) {
      this.valueChange.emit(this.value);
      this.change.emit();
    }
  }

  _focusIn() {
    if (this.focusOutTimeout) {
      clearTimeout(this.focusOutTimeout);
    }
    this.hasFocus.next(true);
    this.focusOutLocked = true;
    setTimeout(() => {
      this.focusOutLocked = false;
    }, 500);

  }

  _focusOut() {
    this.focusOutTimeout = setTimeout(() => {
      this._focusOut2();
    }, 100);
  }

  _focusOut2() {
    if (!this.focusOutLocked) {
      this.hasFocus.next(false);
      if (this.value && !isNaN(this.value)) {
        this.value = parseFloat(this.value);
        if (this.step < 0.1) {
          this.value = this.value.toFixed(2);
        } else if (this.step < 1) {
          this.value = this.value.toFixed(1);
        } else {
          this.value = this.value.toFixed(0);
          this._change();
        }
      }
    }
  }

  _clickInputDiv() {
    this.focusOutLocked = true;
    setTimeout(() => {
      this.focusOutLocked = false;
    }, 100);
  }

  _focOut(elem: NgModel = undefined) {
    this.inputHasFocus.next(true);
    if (elem && elem.value == '') {
      elem.reset(this.focusLastVal);
    }
    this.focusElem = undefined;
  }

  _focIn(elem: NgModel = undefined, event = undefined) {
    if (this.focusOutTimeout) {
      clearTimeout(this.focusOutTimeout);
    }
    let target = undefined;
    if (event) {
      target = event.target;
    }
    if (!this.focusElem || this.focusElem != target) {
      this.focusElem = target;
      this.inputHasFocus.next( true);
      if (elem) {
        this.focusLastVal = elem.value;
        elem.reset('');
      }
    }
  }
}
