import {Component, Input} from "@angular/core";
import {Artikel} from "../../../../models/artikel";
import {MatDialog} from "@angular/material/dialog";
import {
  ShopDialogsAlternativArtikelSucheComponent
} from "../../dialogs/alternativ-artikel-suche/shop.dialogs.alternativ-artikel-suche.component";
import {View} from "../../../../models/view";

@Component({
  selector: 'alternativ-artikel-button',
  templateUrl: 'shop.alternativ-artikel-button.component.html',
  styleUrls: ['shop.alternativ-artikel-button.component.scss'],
})
export class ShopAlternativArtikelButtonComponent {
  @Input() disabled: boolean = false;
  @Input() artikel: Artikel;
  @Input() view: View;

  constructor(private dialog: MatDialog) {
  }

  check() {
    this.dialog.open(
      ShopDialogsAlternativArtikelSucheComponent,
      {
        data: {
          artikel: this.artikel,
          view: this.view
        },
        width: '99vw',
        minWidth: '99vw'
      }
    )
  }
}
