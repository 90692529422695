<span matTooltip="">
  <button (click)="check()" (contextmenu)="$event.preventDefault()"
          [disabled]="disabled"
          mat-button matTooltip="Alternative Produkte">
    <svg-icon
      color="{{disabled ? 'grey' : '#00acd6'}}">
      produktsuche_alternativ
    </svg-icon>
  </button>
</span>
