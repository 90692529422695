import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Artikel} from "../../../../models/artikel";
import {ArtikelKlassifizierungTyp} from "../../../../models/artikel-klassifizierung-typ";
import {BehaviorSubject, Subject} from "rxjs";
import {AppArticleService} from "../../../../services/app.article.service";
import {takeUntil} from "rxjs/operators";
import {AppMobileService} from "../../../../services/app.mobile.service";
import {ArtikelWithAktionen} from "../../../../models/artikel-with-aktionen";
import {AppSettingsService} from "../../../../services/app.app_settings.service";
import {ShopViewInterface} from "../../interfaces/shop.view-interface";
import {View} from "../../../../models/view";
import {ShopViewActionComponent} from "../../components/view-action/shop.view-action.component";
import {ArtikelAktion} from "../../../../models/artikel-aktion";

@Component({
  templateUrl: 'shop.dialogs.alternativ-artikel-suche.component.html',
  styleUrls: ['shop.dialogs.alternativ-artikel-suche.component.scss']
})
export class ShopDialogsAlternativArtikelSucheComponent implements ShopViewInterface, OnInit, OnDestroy {
  klassifizierungen = new BehaviorSubject<ArtikelKlassifizierungTyp[]>(null);
  klassifizierung: string;

  onlyOnStock = true;

  page = 1;
  limit = 20;

  total_count = new BehaviorSubject(0);

  pageSizes = [5, 10, 15, 20, 25, 50];

  entries = new BehaviorSubject<Artikel[]>(null);
  loading$ = new BehaviorSubject(false);

  isMobile = new BehaviorSubject(false);

  _destroy = new Subject();

  layout = new BehaviorSubject<string>('table');
  layoutMobile = new BehaviorSubject<string>('box');

  visible_artikel_aktionen = new BehaviorSubject<ArtikelWithAktionen>(null);

  fsize: string;


  actioncomps: ShopViewActionComponent[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {artikel: Artikel, view: View, wantedAmount: number}, private svc: AppArticleService,
              private msvc: AppMobileService, private settsvc: AppSettingsService,
              private ref: MatDialogRef<ShopDialogsAlternativArtikelSucheComponent>) {
  }

  setBoxLayout() {
    if (this.isMobile.value) {
      this.layoutMobile.next('box');
    } else {
      this.layout.next('box');
    }
  }

  setTableLayout() {
    if (this.isMobile.value) {
      this.layoutMobile.next('table');
    } else {
      this.layout.next('table');
    }
  }

  getKlassifizierungLabel() {
    let label = "";
    this.klassifizierungen.value.some(t => {
      if (t.id == this.klassifizierung) {
        label = t.label;
        return true;
      } else {
        return false;
      }
    });
    return label;
  }

  load() {
    this.loading$.next(true);
    this.svc.findAlternativprodukte(this.data.artikel, this.klassifizierung, this.onlyOnStock, this.page, this.limit).subscribe(
      resp => {
        this.total_count.next(resp.count);
        this.entries.next(resp.data);
        this.loading$.next(false);
      }
    );
  }

  doPage(ev) {
    this.page = ev.pageIndex + 1;
    this.limit = ev.pageSize;
    this.load();
  }

  search() {
    this.page = 1;
    this.load();
  }

  ngOnInit() {
    this.loading$.next(true);
    this.msvc.isMobile().pipe(takeUntil(this._destroy)).subscribe(m => {
      if (this.isMobile.value != m) {
        this.isMobile.next(m);
      }
    });
    this.svc.getDefaultKlassifizierungstyp().subscribe(defaultValue => {
      this.klassifizierung = defaultValue;
      this.load();
    });
    this.settsvc.getUserSettingValue('ShopViewArticleFontSize').subscribe(s => {
      switch (s) {
        case 1:
          this.fsize = 'font-size-1';
          break;
        case 2:
          this.fsize = 'font-size-2';
          break;
        case 3:
          this.fsize = 'font-size-3';
          break;
        case 4:
          this.fsize = 'font-size-4';
          break;
        case 5:
          this.fsize = 'font-size-5';
          break;
        default:
          this.fsize = 'font-size-1';
      }
    });
    this.svc.getKlassifizierungstypen().subscribe(typen => this.klassifizierungen.next(typen));
  }

  close() {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  show_warning = new BehaviorSubject(false);

  focusNextMenge(called_elem) {
    let idx = this.actioncomps.indexOf(called_elem);
    if (this.actioncomps[idx + 1]) {
      this.actioncomps[idx + 1].focusMenge();
    }
  }

  registerViewActionComponent(comp: ShopViewActionComponent) {
    this.actioncomps.push(comp);
    comp.entry.menge = this.data.wantedAmount;
  }

  unregisterViewActionComponent(comp: ShopViewActionComponent) {
    const idx = this.actioncomps.indexOf(comp);
    this.actioncomps.splice(idx, 1);
  }

  showWarning(state: boolean) {
    this.show_warning.next(state);
  }
  showAktion(artikel: Artikel, aktionen: ArtikelAktion[]) {
    const a = new ArtikelWithAktionen();
    a.artikel = artikel;
    a.aktionen = aktionen;
    this.visible_artikel_aktionen.next(a);
  }

  hideAktionen() {
    this.visible_artikel_aktionen.next(null);
  }
}
