<div mat-dialog-title>
  <div style="float: left; padding: 10px 0">
    <svg-icon>vorschau</svg-icon>
    <span *ngIf="data.file" style="vertical-align: middle">{{ data.file.filename }}.{{ data.file.extension }}</span>
    <span *ngIf="!data.file" style="vertical-align: middle">Vorschau</span>
  </div>
  <div style="float: right; padding: 5px 0">
    <button mat-icon-button (click)="close()">
      <svg-icon>error-clean</svg-icon>
    </button>
  </div>
  <div style="clear: both"></div>
</div>



<div mat-dialog-content>
  <div *ngIf="loading|async" style="text-align: center">
    <div style="display: inline-block">
      <div>
        <mat-spinner></mat-spinner>
      </div>
      <div>
        Lade...
      </div>
    </div>
  </div>
  <div *ngIf="show_pdf_viewer" #pdfviewerdiv style="width: 70vw;">
    <ng2-pdfjs-viewer #pdfViewer
                      [print]="true"
                      [pagemode]="'none'"
                      [download]="true"
                      [viewBookmark]="false"
                      [openFile]="false"
                      style="height: 100%"
    ></ng2-pdfjs-viewer>
  </div>
  <div *ngIf="image">
    <div style="text-align: right">
      <button mat-icon-button (click)="downloadImage()" matTooltip="Herunterladen">
        <mat-icon>cloud_download</mat-icon>
      </button>
    </div>
    <div style="text-align: center">
      <div style="background-color: grey; padding: 20px">
        <div style="display: inline-block; max-width: 80%; border: 1px solid darkgrey; line-height: 0; -webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.75);
box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.75);">
          <img [src]="image" style="max-width: 100%">
        </div>
      </div>
    </div>
  </div>
</div>
