import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TryAddArtikelToWkResponse} from "../../models/try-add-artikel-to-wk-response";

@Component({
  templateUrl: 'shop.dialogs.tryadd-art-to-wk-confirm.component.html',
  styleUrls: ['shop.dialogs.tryadd-art-to-wk-confirm.component.scss']
})
export class ShopDialogsTryaddArtToWkConfirmComponent {


  constructor(public dialogRef: MatDialogRef<ShopDialogsTryaddArtToWkConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                state: TryAddArtikelToWkResponse,
                amount: number
              }) {
  }


  setResponse(state, amount: number) {
    this.dialogRef.close({
      state: state,
      amount: amount
    });
  }
}
