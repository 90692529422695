<div style="width: 99vw" *ngIf="klassifizierung">
  <h1 mat-dialog-title>
    <table style="width: 100%">
      <tr>
        <td>
          <svg-icon>produktsuche_alternativ</svg-icon>
          Alternativen zu {{data.artikel.ArtikelName}} ({{data.artikel.PZN}})
          <div *ngIf="data.wantedAmount > 1">
            Sie benötigen {{data.wantedAmount}} Stück.
          </div>
        </td>
        <td>
          <mat-checkbox [(ngModel)]="onlyOnStock" (change)="search()">Nur Lagernde</mat-checkbox>
        </td>
        <td style="text-align: right; width: 1%">
          <mat-form-field appearance="outline">
            <mat-label>Gewählte Klassifizierung</mat-label>
            <mat-select [(value)]="klassifizierung" (selectionChange)="search()">
              <mat-option [value]="t.id" *ngFor="let t of klassifizierungen|async">
                {{t.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td style="text-align: right; width: 1%">
          <mat-button-toggle-group>
            <mat-button-toggle (click)="setTableLayout()"
                               [disabled]="(isMobile|async) ? ((layoutMobile|async) == 'table') : ((layout|async) == 'table')"
                               class="tut_shopview_view_tab"
                               matTooltip="Tabellen-Ansicht">
              <svg-icon>tabellarische-ansicht</svg-icon>
            </mat-button-toggle>

            <mat-button-toggle (click)="setBoxLayout()"
                               [disabled]="(isMobile|async) ? ((layoutMobile|async) == 'box') : ((layout|async) == 'box')"
                               matTooltip="Box-Ansicht"
                               class="tut_shopview_view_box">
              <svg-icon>box-ansicht</svg-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </td>
        <td style="text-align: right; width: 100px">
          <button mat-raised-button (click)="close()" matTooltip="Schließen">
            <svg-icon>error-clean</svg-icon>
          </button>
        </td>
      </tr>
    </table>
  </h1>
  <div mat-dialog-content style="min-height: 200px">
    <div>
      <div class="view_content {{fsize}}">
        <div *ngIf="(total_count|async) == 0 && !(loading$|async)" style="clear: both"></div>
        <div *ngIf="(total_count|async) == 0 && !(loading$|async)" class="infobox">
          <h3>Keine Ergebnisse gefunden!</h3>
        </div>
        <div *ngIf="(loading$|async)" style="clear: both"></div>
        <div *ngIf="(loading$|async)" class="infobox">
          <div style="text-align: center">
            <div style="display: inline-block">
              <mat-spinner></mat-spinner>
            </div>
          </div>
          <h3>Suche läuft!</h3>
        </div>
        <div *ngIf="!(loading$|async)">
          <shop-view-result-table
            *ngIf="(total_count|async) > 0 && (isMobile|async) ? ((layoutMobile|async) == 'table') : ((layout|async) == 'table')"
            [entries]="entries"
            [viewcomp]="this"
            [view]="data.view"
            [infoText]="(loading$|async) ? 'Lade...' : ((total_count|async)+' Artikel')"
            [visible_artikel_aktionen]="visible_artikel_aktionen"
            [showView]="true"
            [fieldsSaveable]="false"
          ></shop-view-result-table>
          <shop-view-result-box
            *ngIf="(total_count|async) > 0 && (isMobile|async) ? ((layoutMobile|async) == 'box') : ((layout|async) == 'box')"
            [entries]="entries"
            [viewcomp]="this"
            [view]="data.view"
            [infoText]="(loading$|async) ? 'Lade...' : ((total_count|async)+' Artikel')"
            [visible_artikel_aktionen]="visible_artikel_aktionen"
            [showView]="true"
            [fieldsSaveable]="false"
          ></shop-view-result-box>
        </div>
      </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <mat-paginator [pageIndex]="page-1" [pageSize]="limit" [length]="total_count|async" [pageSizeOptions]="pageSizes"
    (page)="doPage($event)"></mat-paginator>
  </div>
</div>
