import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TryAddArtikelToWkResponse} from "../../models/try-add-artikel-to-wk-response";

@Component({
  templateUrl: 'shop.dialogs.verfuegbarkeits-check-confirm.component.html',
  styleUrls: ['shop.dialogs.verfuegbarkeits-check-confirm.component.scss']
})
export class ShopDialogsVerfuegbarkeitsCheckConfirmComponent {
  constructor(public dialogRef: MatDialogRef<ShopDialogsVerfuegbarkeitsCheckConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                titel: string,
                msg: string,
                showAlternativeArticleButton: boolean,
                showSplitAlternativeArticleButton: boolean,
                showOnlyMoeglich: boolean,
              }) {
  }


  setResponse(state: string) {
    this.dialogRef.close(state);
  }
}
